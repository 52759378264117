import { Link } from "gatsby";
import { MainImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import { motion } from "framer-motion";

import logoWhite from "../../assets/logo white.svg";
import logo from "../../assets/logo.svg";
import facebook from "../../assets/icons/facebook.svg";
import instagram from "../../assets/icons/instagram.svg";
import linkedin from "../../assets/icons/linkedin.svg";
import twitter from "../../assets/icons/twitter.svg";
import burgerClose from "../../assets/icons/burger close.svg";

import { navBarAnimation } from "../../utils/motion";

const headerLinks = [
  {
    title: <>Home</>,
    link: "",
  },
  {
    title: <>Our&nbsp;story</>,
    link: "our_story",
  },
  {
    title: <>Our&nbsp;locations</>,
    link: "our_locations",
  },
  {
    title: <>Gallery</>,
    link: "gallery",
  },
  {
    title: <>Contact&nbsp;us</>,
    link: "contact_us",
  },
];
const socialMedia = [
  {
    title: "instagram",
    icon: instagram,
    link: "https://www.instagram.com/farsa.restaurant/",
  },
  {
    title: "facebook",
    icon: facebook,
    link: "https://www.facebook.com/farsagroup",
  },
  {
    title: "twitter",
    icon: twitter,
    link: "",
  },
  {
    title: "linkedin",
    icon: linkedin,
    link: "",
  },
];

const Header = () => {
  const [isToggle, setIsToggle] = useState(false);
  const isWindowHome =
    typeof window !== undefined && window.location.pathname === "/";

  return (
    <div className="absolute w-full main_padding sm:top-20 top-8 z-50">
      <div className="max-w-7xl mx-auto sm:flex hidden items-center justify-center gap-7 ">
        <Link
          to="/our_story"
          className={`font-Calisga text-sm  ${
            isWindowHome ? "text-[#F6ECE0]" : "text-brown"
          }`}
        >
          Our story
        </Link>
        <Link
          to="/our_locations"
          className={`font-Calisga text-sm  ${
            isWindowHome ? "text-[#F6ECE0]" : "text-brown"
          }`}
        >
          Our locations
        </Link>
        <Link to="/">
          <MainImage
            src={isWindowHome ? logoWhite : logo}
            alt="logo"
            width={100}
            height={100}
            loading="lazy"
            className="object-contain max-h-[60px] w-auto"
          />
        </Link>
        <Link
          to="/gallery"
          className={`font-Calisga text-sm  ${
            isWindowHome ? "text-[#F6ECE0]" : "text-brown"
          }`}
        >
          Gallery
        </Link>
        <Link
          to="/contact_us"
          className={`font-Calisga text-sm  ${
            isWindowHome ? "text-[#F6ECE0]" : "text-brown"
          }`}
        >
          Contact us
        </Link>
      </div>

      {/* mobile  */}

      {/* tab burger */}
      <div className="flex sm:hidden justify-between items-center">
        <Link to="/">
          <MainImage
            src={isWindowHome ? logoWhite : logo}
            alt="logo"
            width={100}
            height={100}
            loading="lazy"
            className="object-contain max-h-[60px] w-auto"
          />
        </Link>
        <div className="">
          <svg
            width="35"
            height="36"
            viewBox="0 0 25 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setIsToggle(!isToggle)}
          >
            <path
              d="M17.7463 0H7.26612C2.71385 0 0 2.71385 0 7.26612V17.7338C0 22.2986 2.71385 25.0125 7.26612 25.0125H17.7338C22.2861 25.0125 25 22.2986 25 17.7463V7.26612C25.0125 2.71385 22.2986 0 17.7463 0ZM18.7593 19.072H6.25312C5.74036 19.072 5.31515 18.6468 5.31515 18.134C5.31515 17.6213 5.74036 17.1961 6.25312 17.1961H18.7593C19.2721 17.1961 19.6973 17.6213 19.6973 18.134C19.6973 18.6468 19.2721 19.072 18.7593 19.072ZM18.7593 13.4442H6.25312C5.74036 13.4442 5.31515 13.019 5.31515 12.5062C5.31515 11.9935 5.74036 11.5683 6.25312 11.5683H18.7593C19.2721 11.5683 19.6973 11.9935 19.6973 12.5062C19.6973 13.019 19.2721 13.4442 18.7593 13.4442ZM18.7593 7.8164H6.25312C5.74036 7.8164 5.31515 7.39118 5.31515 6.87843C5.31515 6.36567 5.74036 5.94046 6.25312 5.94046H18.7593C19.2721 5.94046 19.6973 6.36567 19.6973 6.87843C19.6973 7.39118 19.2721 7.8164 18.7593 7.8164Z"
              className={
                isToggle
                  ? "hidden"
                  : isWindowHome
                  ? "fill-[#F6ECE0]"
                  : "fill-[#4F2B28]"
              }
            />

            <path
              d="M18.3752 0.150757H7.89515C3.34315 0.150757 0.62915 2.86476 0.62915 7.41676V17.8848C0.62915 22.4498 3.34315 25.1638 7.89515 25.1638H18.3631C22.9151 25.1638 25.6292 22.4498 25.6292 17.8968V7.41676C25.6422 2.86476 22.9282 0.150757 18.3752 0.150757Z"
              fill="#F6ECE0"
              className={
                !isToggle
                  ? "hidden"
                  : isWindowHome
                  ? "fill-[#F6ECE0]"
                  : "fill-[#4F2B28]"
              }
            />
            <path
              d="M6.46301 5.50699L19.854 19.778Z"
              className={
                !isToggle
                  ? "hidden"
                  : isWindowHome
                  ? "fill-[#D8D8D8]"
                  : "fill-[#E9C8A7]"
              }
            />
            <path
              d="M6.46301 5.50699L19.854 19.778"
              strokeWidth="2"
              strokeLinecap="round"
              className={
                !isToggle
                  ? "hidden"
                  : isWindowHome
                  ? " stroke-black"
                  : "stroke-[#E9C8A7] "
              }
            />
            <path
              d="M20.3612 6.06412L6.09021 19.4551Z"
              fill="#D8D8D8"
              className={
                !isToggle
                  ? "hidden"
                  : isWindowHome
                  ? "fill-[#D8D8D8]"
                  : "fill-[#E9C8A7]"
              }
            />
            <path
              d="M20.3612 6.06412L6.09021 19.4551"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              className={
                !isToggle
                  ? "hidden"
                  : isWindowHome
                  ? " stroke-black"
                  : "stroke-[#E9C8A7] "
              }
            />
          </svg>
        </div>
        <motion.nav
          initial={false}
          animate={isToggle ? "true" : "false"}
          className="fixed z-50 bg-[#231A19] w-full h-full min-h-screen left-0 top-0 footerBg p-12 grid justify-center"
          variants={navBarAnimation}
        >
          <motion.div className="">
            <Link
              to="/"
              onClick={() => setIsToggle(false)}
              className="flex items-center justify-center"
            >
              <MainImage
                src={logoWhite}
                alt="logo"
                width={100}
                height={100}
                loading="lazy"
                className="object-contain h-[70px] w-auto"
              />
            </Link>
            <ul className="grid justify-items-center gap-10 mt-12 ">
              {headerLinks.map((item, idx) => (
                <li key={idx}>
                  <Link
                    to={`/${item.link}`}
                    onClick={() => setIsToggle(false)}
                    className="text-[#F6F3E2] font-Calisga font-medium text-lg tracking-[0.01em] shrink-0"
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
            <ul className="mt-10 flex items-center gap-[14px]">
              {socialMedia.map((social, socialIdx) => (
                <li key={socialIdx}>
                  <Link
                    to={social.link}
                    target="_blank"
                    rel="noreferrer" aria-label="social media"
                    onClick={() => setIsToggle(false)}
                    className="w-[28px] h-[28px] flex items-center justify-center rounded-md bg-[#1E100E]"
                  >
                    <MainImage
                      src={social.icon}
                      alt={social.title}
                      width={50}
                      height={50}
                      className="object-contain h-[40%] w-auto"
                    />
                  </Link>
                </li>
              ))}
            </ul>
          </motion.div>
          <div className="flex items-center justify-center">
            <button onClick={() => setIsToggle(false)} className="">
              <MainImage
                src={burgerClose}
                alt="burger open"
                width={26}
                height={26}
                className="object-contain h-[46px] w-auto select-none"
              />
            </button>
          </div>
        </motion.nav>
      </div>
    </div>
  );
};

export default Header;
