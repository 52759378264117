export const navBarAnimation = {
  true: () => ({
    translateX: "0",
    transition: {
      duration: .5,
    },
  }),
  false: {
    translateX: "150%",
    transition: {
      delay: 0.5,
      duration: .5,
    },
  },
};
