module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Farsa Restaurant | Belongs to you since 1940","short_name":"Farsa Restaurant","description":"Enjoy the legacy of Farsa Restaurant, serving you since 1940. Join us for a memorable dining experience in Farsa, where tradition meets taste.","lang":"en","display":"standalone","icon":"src/assets/favicon.png","start_url":"/","background_color":"#663399","theme_color":"#fff","icons":[{"src":"src/assets/favicon.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"src/assets/favicon.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5123c734a9e2a9e2e52c6c0051b5a2c8"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Rubik, sans-serif","file":"https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
