import * as React from "react";
import { AnimatePresence, domAnimation, LazyMotion } from "framer-motion";
import Layout from "./src/components/layout/Layout";
import "./src/styles/global.css";

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This application has been updated. ` +
      `Reload to display the latest version?`
  );

  if (answer === true) {
    window.location.reload();
  }
};

export const registerServiceWorker = () => true;

export const wrapPageElement = ({ element }) => {
  return (
    <LazyMotion features={domAnimation}>
      <AnimatePresence
        mode="wait"
        onExitComplete={() => {
          document.body.classList.remove("overflow-hidden");
        }} 
      >
        <Layout>{element}</Layout>
      </AnimatePresence>
    </LazyMotion>
  );
};
